.login {
  width: 100%;
  max-width: 568px;
  padding: 24px;
  margin: auto;
}

.logo,
.logoImage {
  &Wrapper {
    text-align: center;
  }
  height: 100px;
}

.title {
  margin-top: 16px;
  font-size: 18px;
  text-align: center;
}

.note {
  margin-top: 8px;
  text-align: center;
  font-size: 12px;
  opacity: 0.6;
}

.input {
  margin-top: 16px;
}

.checkboxAndLinkWrapper {
  margin: 8px 0 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 11px;
}

.checkbox {
  display: flex;
  align-items: center;
  & > label {
    opacity: 0.75;
  }
}

.link {
  opacity: 0.75;
}

.button {
  width: 100%;
  height: 40px;
  font-size: 15px;
  font-weight: 400;
  border-radius: 4px;
  &Green {
    // background-color: rgba(49, 88, 69, 1);
    color: var(--white-dark-color);
    background-color: var(--success-main-color);
  }
  &Blue {
    margin-top: 16px;
    border: 1px solid #3a509b;

    // background-color: #3a509b;
  }
}

.whitelabelLink {
  position: absolute;
  left:0;
  right:0;
}

@media (min-width: 768px) {
  .logo {
    height: 174px;
  }
  .logoImage {
    height: 150px;
  }

  .title {
    margin-top: 32px;
    font-size: 32px;
  }

  .note {
    font-size: 14px;
  }

  .input {
    margin-top: 24px;
  }

  .checkboxAndLinkWrapper {
    margin-top: 16px;
  }

  .button {
    &Blue {
      margin-top: 32px;
    }
  }
}
