@import "src/screenSizes";

%linkStyles {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  display: inline-block;
  &:hover {
    text-decoration: none;
  }
}

.lastPresentationScreen {
  display: flex;
  height: calc(100% - 60px);
  margin: 30px;
  border-radius: 8px;
  background-color: var(--background-main-color);
  background-repeat: no-repeat;
  background-position: left center;
  background-size: cover;
}

.content {
  display: flex;
  width: 100%;
  padding: 30px;

  gap: 40px;
}

.textsAndButtons {
  width: 50%;
  margin: auto 0;
}

.dashboardName {
  font-size: 48px;
  line-height: 60px;
  margin: 0 0 10px 0;
}

.description {
  font-size: 20px;
  line-height: 30px;
  margin: 0;
}

.buttons {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 45px;

  gap: 10px;
}

.redLink {
  padding: 15px 20px;
  color: var(--font-light-color);
  border-radius: 4px;
  background-color: var(--ocean-outdoor-gray);

  @extend %linkStyles;

  &:disabled {
    color: var(--font-disabled-color);
    background-color: var(--button-background-disabled-color);
  }
}

.transparentLink {
  padding: 15px 20px;
  color: var(--ocean-outdoor-gray);
  border: 1px solid var(--ocean-outdoor-gray);
  border-radius: 4px;

  @extend %linkStyles;
}

.separator {
  display: inline-block;
  margin-right: 10px;
  color: var(--font-main-color);

  @extend %linkStyles;
}

.goBackLink {
  & > a {
    color: var(--ocean-outdoor-gray);

    @extend %linkStyles;
  }
}

.imageWrapper {
  display: flex;
  overflow: hidden;
  width: calc(50% + 30px);
  transform: translateX(30px);

  & > img {
    max-width: calc(100% + 170px);
    max-height: 100%;
  }
}

[dark-theme] {
  .lastPresentationScreen {
    background-color: var(--dark-theme-background-dark-color);
  }

  .separator {
    color: var(--dark-theme-font-main-color);
  }
}

@media screen and (max-width: $mobileScreen) {
  .content {
    align-items: center;
    flex-direction: column;
    margin: auto;
  }

  .textsAndButtons {
    width: 100%;
    margin: unset;
  }

  .imageWrapper {
    width: 100%;
    transform: translateX(0px);
    & > img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .dashboardName {
    font-size: 30px;
    line-height: 42px;
  }
  .description {
    font-size: 18px;
    line-height: 24px;
  }
}
