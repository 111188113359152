@import "./components/generalLinkStyles.module.scss";

$sideBarWidth: 200px;
$userNameLineHeight: 20px;
$userNameMaxLines: 2;

.sidebar {
  position: fixed;
  z-index: 1;
  top: var(--actionBar-height);
  bottom: 0;
  left: 0;
  &Closed {
    display: none;
  }
}

.content {
  font-size: 14px;
  font-weight: 400;
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: $sideBarWidth;
  height: 100%;
  margin-right: 18px;
  padding: 0 0 20px 0;
  transition: all .25s;
  color: #efe9ea;
  background-color: var(--ocean-outdoor-gray-dark);
  box-shadow: 0 3px 6px var(--main-box-shadow-color);
}

.links {
  display: flex;
  overflow-y: auto;
  flex: 1 1;
  flex-direction: column;

  a,
  button {
    margin-top: 5px;
  }

  svg {
    width: 22px;
    height: 22px;
  }

  &::-webkit-scrollbar {
    width: 15px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: var(--graphite-dark-color);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--dark-theme-background-light-color);
  }
}

.contentNarrow {
  width: $sideBarWidth;

  .link {
    justify-content: flex-start;
    padding: 0 30px;

    &.profileLink {
      padding: 0 22px;
    }

    & > span {
      display: block;
    }

    svg {
      flex: 0 0 auto;
      margin-right: 10px;
    }
  }

  .arrow {
    transform: rotate(0deg);
  }
}

.arrow {
  position: absolute;
  top: 101px;
  right: -15px;
  display: none;
  cursor: pointer;
  transform: rotate(180deg);

  & > circle {
    fill: var(--ocean-outdoor-gray-dark);
  }
}

.profileLink {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  justify-content: flex-end;
  margin: 10px 0;
  padding: 0 20px;
  opacity: 1;

  & > svg {
    min-width: 40px;
  }

  & > span {
    line-height: $userNameLineHeight;
    max-height: calc(#{$userNameMaxLines} * #{$userNameLineHeight});
    margin-right: 10px;
    white-space: initial;
    word-break: break-word;
  }
}

.exploreLink {
  display: flex;
  align-items: center;
}

.logoutButton {
  margin-top: 40px !important;
}

.center {
  margin: 30px 0;
}

.bottom {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: auto;
}

.logoLink {
  padding: 20px;

  svg {
    max-width: 140px;
    height: 42px;
  }
}

@media screen and (min-width: 991px) {
  .arrow {
    display: block;
  }
  .sidebar {
    position: relative;
    top: 0;
    display: block;
    margin-right: -18px;
  }
}

@media screen and (max-width: 990px) {
  .link.profileLink {
    margin: 20px 0;
  }
}
