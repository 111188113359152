.link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  cursor: pointer;
  text-decoration: none !important;
  opacity: .6;

  & > span {
    font-size: 14px;
    display: none;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.activeItem {
  position: relative;
  opacity: 1;
  &::after {
    position: absolute;
    left: 0;
    width: 5px;
    height: 100%;
    content: "";
    background-color: var(--ocean-outdoor-gray-light);
  }
}
