@import "src/screenSizes";

.firstPresentationScreen {
  display: flex;
  height: calc(100% - 60px);
  margin: 30px;
  border-radius: 8px;
  background-color: var(--background-main-color);
  background-repeat: no-repeat;
  background-position: left center;
  background-size: cover;
}

.content {
  display: flex;
  flex-direction: column;
  max-width: 750px;
  margin: auto;
  padding: 30px;

  gap: 40px;
}

.dashboardName {
  font-size: 48px;
  line-height: 60px;
  margin: 0 0 10px 0;
  text-align: center;
}

.description {
  font-size: 20px;
  line-height: 30px;
  margin: 0;
  text-align: center;
}

.link {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  display: inline-block;
  max-width: 156px;
  margin: 0 auto;
  padding: 15px 20px;
  color: var(--font-light-color);
  border-radius: 4px;
  background-color: var(--ocean-outdoor-gray);
  &:hover {
    text-decoration: none;
  }
}

.image {
  max-width: 100%;
  max-height: 100%;
}

[dark-theme] {
  .firstPresentationScreen {
    background-color: var(--dark-theme-background-dark-color);
  }
}


@media screen and (max-width: $mobileScreen) {
  .dashboardName {
    font-size: 30px;
    line-height: 42px;
  }
  .description {
    font-size: 18px;
    line-height: 24px;
  }
}
