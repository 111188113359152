.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  svg {
    width: auto;
  }

  a {
    margin-top: 3px;
  }

  &_small {
    height: 75px;

    .label {
      font-size: 8px;
    }

    svg {
      height: 15px;
      max-width: 150px;
    }
  }

  &_medium {
    height: 125px;

    .label {
      font-size: 16px;
    }

    svg {
      height: 40px;
      max-width: 150px;
    }
  }

  &_large {
    height: 200px;

    .label {
      font-size: 21px;
    }

    svg {
      height: 100px;
      max-width: 300px;
    }
  }
}

