@import "react-datepicker/dist/react-datepicker.css";

.picker {
  height: 24px;

  div[class~="react-datepicker__input-container"] {
    button:disabled {
      opacity: .4;
    }
  }
}

.datePicker {
  div[class~="react-datepicker__header"] {
    padding-bottom: 8px;
    color: white;
    background-color: var(--ocean-outdoor-gray-dark);
  }
  div[class~="react-datepicker__triangle"] {
    border-bottom-color: var(--ocean-outdoor-gray-dark) !important;
  }
  button[class~="react-datepicker__navigation--previous"] {
    border-right-color: white;
  }
  button[class~="react-datepicker__navigation--next"] {
    border-left-color: white;
  }

  div[class~="react-datepicker__month-text--keyboard-selected"] {
    color: black;
    background-color: transparent;
    &:hover {
      background-color: #f0f0f0;
    }
  }

  div[class~="react-datepicker__month--selected"] {
    color: white;
    background-color: #2a87d0;
    &:hover {
      background-color: #2a87d0;
    }
  }

  div[class~="react-datepicker__month--disabled"] {
    color: #ccc;
  }

  div[class~="react-datepicker__month--in-range"] {
    color: white;
    background-color: #216ba5;
    &:hover {
      background-color: #2a87d0;
    }
  }
}

.datePicker .month {
  padding: 10px;
  border-radius: 20px !important;
  &:hover {
    border-radius: 20px !important;
  }
  &[class~="react-datepicker__month--disabled"] {
    color: #ccc;
    background-color: transparent;
  }
}
