@import "src/screenSizes";

.wrapper {
  position: relative;

  div[class~="react-datepicker-popper"] {
    &[data-placement^="bottom"] {
      margin-top: 5px;
    }

    &[data-placement^="top"] {
      margin-bottom: 5px;
    }
  }
}

.input {
  width: 100%;
  height: 40px;
  padding: 12px 15px;
  border: 1px solid var(--border-light-color);
  border-radius: 4px;

  &.fillInput {
    border-color: var(--border-main-color) !important;
  }

  &::placeholder {
    color: var(--font-disabled-color);
  }
}

.icon {
  position: absolute;
  top: 19%;
  right: 6%;
  pointer-events: none;
}

.datePickerWrapper {
  width: 100%;
}

.datePickerCalendar {
  padding: 20px;
  border-radius: 8px;
  background-color: var(--background-main-color);
  box-shadow: 0 0 36px rgba(139, 139, 139, .1);

  &[class~="react-datepicker"] {
    border-color: var(--border-light-color) !important;
  }

  div[class~="react-datepicker__triangle"] {
    display: none;
  }

  div[class~="react-datepicker__header"] {
    padding: 0;
    text-align: left;
    border: 0;
    background-color: var(--background-main-color);
  }

  button[class~="react-datepicker__navigation--previous"] {
    position: absolute;
    top: 27px;
    left: 80%;
    display: inline-block;
    width: 5px;
    height: 5px;
    padding: 4px;
    transform: rotate(135deg);
    border: solid var(--graphite-dark-color);
    border-width: 0 2px 2px 0;
  }

  button[class~="react-datepicker__navigation--next"] {
    position: absolute;
    top: 27px;
    right: 10%;
    display: inline-block;
    width: 5px;
    height: 5px;
    padding: 4px;
    transform: rotate(-45deg);
    border: solid var(--graphite-dark-color);
    border-width: 0 2px 2px 0;
  }

  div[class~="react-datepicker__month-container"] {
    float: none;
  }

  div[class~="react-datepicker__current-month"] {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    display: inline;
    padding-left: 10px;
  }

  div[class~="react-datepicker__header__dropdown"] {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
  }

  div[class~="react-datepicker__header__dropdown"] {
    display: inline-block;
    margin-bottom: 20px;
  }

  div[class~="react-datepicker__day-names"] {
    border-top: 1px solid var(--border-light-color);
  }

  div[class~="react-datepicker__day-name"] {
    font-size: 14px;
    font-weight: 400;
    line-height: 40px;
    width: 40px;
    height: 40px;
    color: var(--ocean-outdoor-gray-dark);
  }

  div[class~="react-datepicker__day"] {
    font-size: 14px;
    font-weight: 400;
    line-height: 40px;
    width: 40px;
    height: 40px;
    vertical-align: center;
    color: var(--graphite-dark-color);
    border-radius: 50%;
  }

  div[class~="react-datepicker__month"] {
    margin: 0;
  }

  div[class~="react-datepicker__day--today"] {
    color: var(--ocean-outdoor-gray);
    border: 1px solid var(--ocean-outdoor-gray);
    border-radius: 50%;

    &:hover {
      background-color: var(--ocean-outdoor-gray-light);
    }
  }

  div[class~="react-datepicker__day--selected"] {
    color: var(--font-light-color);
    border-radius: 50%;
    background-color: var(--ocean-outdoor-gray);

    &:hover {
      background-color: var(--ocean-outdoor-gray);
    }
  }

  div[class~="react-datepicker__day--keyboard-selected"] {
    background-color: transparent;
  }

  div[class~="react-datepicker__day--disabled"],
  div[class~="react-datepicker__day--outside-month"] {
    color: rgba(36, 43, 67, .15);
  }

  div[class~="react-datepicker__year-dropdown"] {
    left: 0;
    border: 1px solid var(--border-light-color);
    border-radius: 4px;
    box-shadow: 0 0 16px rgba(31, 31, 31, .05);
  }

  div[class~="react-datepicker__year-option"] {
    font-size: 14px;
    padding: 12px 15px;
    color: var(--graphite-dark-color);
    background-color: var(--background-main-color);
  }

  span[class~="react-datepicker__year-option--selected"] {
    display: none;
  }

  div[class~="react-datepicker__year-option--selected_year"] {
    font-weight: 600;
    color: var(--ocean-outdoor-gray-dark);
  }

  span[class~="react-datepicker__year-read-view--down-arrow"] {
    display: inline-block;
    width: 2px;
    height: 2px;
    margin: -3px 10px;
    padding: 3px;
    transform: rotate(45deg);
    border: solid var(--graphite-dark-color);
    border-width: 0 2px 2px 0;
    &:before {
      display: none;
    }
  }

  div[class~="react-datepicker__year-read-view"] {
    font-size: 16px;
    font-weight: 500;
    visibility: visible !important;
  }

  a[class~="react-datepicker__navigation--years"] {
    width: 2px;
    height: 2px;
    padding: 3px;
    transform: rotate(-135deg);
    border: solid var(--graphite-dark-color);
    border-width: 0 2px 2px 0;
  }

  a[class~="react-datepicker__navigation--years-upcoming"] {
    transform: rotate(-135deg);
  }

  a[class~="react-datepicker__navigation--years-previous"] {
    transform: rotate(45deg);
  }
}

[dark-theme] {
  .input {
    color: var(--dark-theme-font-main-color);
    border-color: var(--dark-theme-border-dark-color) !important;
    background-color: var(--dark-theme-background-dark-color);

    &.fillInput {
      border-color: var(--dark-theme-border-main-color) !important;
    }

    &::placeholder {
      color: var(--dark-theme-font-disabled-color);
    }
  }

  .datePickerCalendar {
    color: var(--dark-theme-font-main-color);
    background-color: var(--dark-theme-background-dark-color);
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16);

    &[class~="react-datepicker"] {
      border-color: var(--dark-theme-border-dark-color) !important;
    }

    div[class~="react-datepicker__header"] {
      color: var(--dark-theme-font-main-color);
      background-color: var(--dark-theme-background-dark-color);
    }

    div[class~="react-datepicker__day-name"] {
      color: inherit;
    }

    div[class~="react-datepicker__day-names"] {
      border-top: 1px solid var(--dark-theme-border-dark-color);
    }

    span[class~="react-datepicker__year-read-view--down-arrow"],
    button[class~="react-datepicker__navigation"],
    a[class~="react-datepicker__navigation--years"] {
      border-color: var(--dark-theme-font-main-color);
    }

    div[class~="react-datepicker__year-dropdown"] {
      border-color: var(--dark-theme-border-dark-color);
      background-color: var(--graphite-dark-color);
    }

    div[class~="react-datepicker__year-option"] {
      color: var(--dark-theme-font-main-color);
      background-color: var(--dark-theme-background-dark-color);
    }

    div[class~="react-datepicker__year-option--selected_year"] {
      color: var(--dark-theme-graphite-light-color);
    }

    div[class~="react-datepicker__current-month"] {
      color: var(--dark-theme-font-main-color);
    }

    div[class~="react-datepicker__day"] {
      color: var(--dark-theme-font-main-color);
      background-color: var(--dark-theme-background-dark-color);

      &:hover {
        background-color: rgba(256, 256, 256, .15);
      }
    }

    div[class~="react-datepicker__day--today"] {
      border: 1px solid var(--dark-theme-graphite-light-color);

      &:hover {
        background-color: var(--dark-theme-graphite-light-color);
      }
    }

    div[class~="react-datepicker__day--selected"] {
      color: var(--dark-theme-font-main-color);
      background-color: var(--dark-theme-graphite-light-color);

      &:hover {
        background-color: var(--dark-theme-graphite-light-color);
      }
    }

    div[class~="react-datepicker__day--disabled"] {
      color: rgba(256, 256, 256, .15);

      &:hover {
        background-color: transparent;
      }
    }

    div[class~="react-datepicker__day--outside-month"] {
      color: rgba(256, 256, 256, .15);
    }
  }

  .icon {
    color: var(--dark-theme-border-main-color);
  }
}

@media screen and (max-width: $mobileScreen) {
  .input {
    height: 40px;
  }

  .datePickerCalendar {
    padding: 10px;
  }
}

@media screen and (max-width: $tabletScreen) {
  .icon {
    top: 19%;
    right: 3%;
  }
}
